import React, { useEffect, useState } from 'react'
import { RequestManager } from '../Requests/RequestManager'
import TransformBusiness from '../Transformer/TransformBusiness'


function Dealership() {

    const [dealership, setDealership] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchDealership();

                setDealership(TransformBusiness(res?.data) ?? []);
            } catch (error) {
                console.error("Error in fetching data: ", error);
            }
        }

        init();
    }, [])

    const renderImage = (item, index) => (
        <div key={index} className={`col-6 mt-5 d-flex ${index % 2 === 0 ? 'justify-content-end' : 'justify-content-start'}`}>
            <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="60%" height="150px" alt="" />
        </div>
    )

    const renderDealership = (item, index) => (
        <div key={index} onClick={() => setSelected(index)} id='num_select' className="row mt-5">
            <div className="col-1 d-flex align-items-center justify-content-start flex-column">
                <span className='d-flex align-items-center Hover justify-content-center text-white' style={{ backgroundColor: index === selected ? 'var(--primary)' : 'var(--secondary)', height: '40px', width: '40px', borderRadius: "50%" }}>
                    <strong className='d-flex align-items-start justify-content-center'>
                        {index + 1} <br />
                    </strong>
                </span>

            </div>
            <div className="col ml-auto mt-1">
                <div className="row">
                    <div className="col-12" style={{ fontSize: 'var(--Regular)', cursor: 'pointer' }}>

                        {item.Title}
                    </div>
                    <div className="col-12 mt-2" style={{ color: 'var(--grey)' }}>
                        {index === selected && (
                            <div className='row'>
                                <div dangerouslySetInnerHTML={{ __html: item.Description }} className="col-12" style={{ fontSize: '15px' }}>


                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-center my-5 d-lg-none">
                                    <img src={`${process.env.REACT_APP_API_URL}${dealership[selected].Image}`} width="70%" height="auto" style={{ borderRadius: '10px' }} alt="" />
                                </div>
                                <div className="col-12 mt-2 Hover" style={{ color: 'var(--primary)', fontSize: '13px' }}>
                                    <strong>
                                        <a href={`/dealership/${item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                            Read More &nbsp;
                                        </a>
                                    </strong>
                                    <svg width="29" height="12" viewBox="0 0 29 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.0756 11.0053L22.7017 11.6313L28.084 6.24907L22.7017 0.866786L22.0756 1.49285L26.3897 5.80694L5.09186e-07 5.80694L4.31808e-07 6.69203L26.3897 6.69204L22.0756 11.0053Z" fill="#1B7A99" />
                                    </svg>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>


        </div>
    )
    return (
        <div className='row'>
            <div className="col-12 col-lg-6">
                <div className="row">

                    <div className="col-12 mt-5">
                        {dealership.map(renderDealership)}
                    </div>

                </div>
            </div>
            <div className="col-12  col-lg-6 d-flex align-items-center justify-content-center"
                style={{

                    height: '100%'
                }}
            >
                {dealership.length > 0 && (
                    <div className='row'>
                        {selected === null ? (
                            <div className="col-12">
                                <div className="row ">
                                    {dealership.map(renderImage)}

                                </div>
                            </div>




                        ) : (
                            <div className='row'>
                                <div className="col-12 d-none d-lg-flex align-items-center justify-content-center h-100 py-5">

                                    <img src={`${process.env.REACT_APP_API_URL}${dealership[selected].Image}`} width="70%" height="auto" style={{ borderRadius: '10px' }} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dealership