import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { RequestManager } from '../Requests/RequestManager';
import TransformBusiness from '../Transformer/TransformBusiness';
import Background from './Media/Layer_1.png';
import './ProjectSlider.css';
import { useNavigate } from 'react-router-dom';

function Projects() {

    const [project, setProject] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        const init = async () => {
            try {

                const res = await RequestManager.fetchProjects();
                setProject(TransformBusiness(res?.data) ?? []);

            } catch (error) {
                console.error("Error in fetching projects: ", error);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, []);

    const renderProject = (item, index) => (

        <SwiperSlide key={index}>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <img
                        onClick={() => navigate("/where-we-work")}
                        src={`${process.env.REACT_APP_API_URL}${item.Image}`}
                        width="auto"
                        height="400px"
                        style={{ borderRadius: '15px', cursor:'pointer' }}
                        alt={item.Title || "Project image"}
                    />
                </div>
                <div className="col-12 col-lg-6 d-flex align-items-center" style={{ fontSize: 'var(--Regular)' }}>

                    <div onClick={() => navigate("/where-we-work")} className="row Slider_Hover" style={{ cursor: 'pointer' }}>
                        <div className="col-12" style={{ color: 'var(--primary)' }}>
                            <strong>{item.Client}</strong>
                        </div>
                        <div className="col-12" style={{ color: 'var(--primary)' }}>
                            PROJECT: {item.Title}
                        </div>
                    </div>
                </div>
            </div>
        </SwiperSlide>
    );

    return (
        <div className="col-12">
            {loading ? (
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        Loading...
                    </div>
                </div>
            ) : (
                <div className="row py-5 d-flex flex-lg-row flex-column-reverse" style={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: '60%',
                    backgroundPosition: 'right bottom',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className='col-lg-10 col-12 py-5' style={{ backgroundColor: 'rgba(247, 247, 247, 0.9)' }}>
                        <Swiper
                            spaceBetween={50}
                            modules={[Navigation]}
                            navigation
                        >
                            {project.map(renderProject)}
                        </Swiper>
                    </div>
                    <div className="col-12 col-lg-2 d-flex align-items-center justify-content-center" style={{
                        color: 'var(--primary)',
                        fontSize: '29px',
                        textTransform: 'uppercase'
                    }}>
                        <strong>
                            what did we<br /> work on?
                        </strong>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Projects;
