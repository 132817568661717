import React, { useEffect, useState, useRef } from 'react'
import Background from './Media/7.png'
import './Contact.css'
import PinIcon from './PinIcon';
import { RequestManager } from '../Requests/RequestManager';
import TransformBusiness from '../Transformer/TransformBusiness'

function Contact() {
    const [location, setLocation] = useState("LEBANON");
    const [ind, setInd] = useState(1);
    const [contact, setContact] = useState([]);
    const pins = [
        { id: 1, title: 'LEBANON', top: '52%', left: '54.55%', search: 'LEBANON' },
        { id: 2, title: 'KSA', top: '67%', left: '57.7%', search: 'KSA' },

        { id: 4, title: 'BAHRAIN', top: '63.5%', left: '61.6%', search: 'BAHRAIN' },
        { id: 7, title: 'UAE', top: '65.5%', left: '62.6%', search: 'UAE' },
        { id: 3, title: 'SULTANATE OMAN', top: '69%', left: '64.1%', search: 'OMAN' },
        { id: 6, title: "COTE D'IVOIRE", top: '75%', left: '52%', search: "COTE D'IVOIRE" },

        { id: 5, title: 'LITHUANIA', top: '23%', left: '49%', search: 'LITHUANIA' },
    ];

    const mapRef = useRef(null);
    const contactRef = useRef(null);

    const handlePinClick = (pin) => {
        setInd(pin.id);
        setLocation(pin.search);

        setTimeout(() => {
            contactRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    const handleShowOnMap = () => {
        mapRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const init = async () => {

            try {
                const res = await RequestManager.fetchContact();

                setContact(TransformBusiness(res?.data));

            } catch (err) {
                console.error("Error in fetching data: ", err);
            }
        }
        init();
    }, [location])


    const renderContact = (item, index) => (
        <div key={index} className="col-12 p-5 mt-5 mt-lg-0  col-lg-4"  >
            <div onClick={() => {
                setLocation(item.Country);
                setInd(item.id);
                handleShowOnMap();
            }}
                className="row h-100 py-5" style={{ backgroundColor: item.Country === location ? 'var(--primary)' : 'white', boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)', borderRadius: '15px', color: item.Country === location ? 'white' : 'var(--grey)', cursor: "pointer" }}>
                <div dangerouslySetInnerHTML={{ __html: item.Location }} className="col-12" style={{ fontWeight: 'bold' }}>
                </div>
                <div className="col-12 mt-3">
                    Tel: {item.Tel}
                </div>
                <div className="col-12 mt-3">
                    Email: {item.Email}
                </div>
                <div className="col-12 d-flex align-items-center mt-3 justify-content-center">
                    <button className='Hover' style={{ borderRadius: '25px', border: 'none', color: item.Country === location ? 'var(--grey)' : 'white', backgroundColor: item.Country === location ? "white" : "var(--primary)", padding: "10px 20px" }}>
                        <strong>
                            {/* <a href={item.Map}></a> */}
                            SHOW ON MAP
                        </strong>
                    </button>
                </div>
            </div>
        </div>
    )


    return (
        <div className="container-fluid px-0">
            <div ref={mapRef} className="map-container">
                <img src={Background} alt="Map" className="map-image" style={{ position: 'relative' }} />
                {pins.map(pin => (
                    <div
                        key={pin.id}
                        href={pin.link}
                        id='Pin'
                        onClick={() => {
                            setInd(pin.id);
                            setLocation(pin.search);
                            handlePinClick(pin);
                        }}
                        className="map-pin d-flex justify-content-center"
                        style={{
                            top: pin.top,
                            left: pin.left,
                            transform: ind === pin.id ? 'translate(-50%, -50%) scale(1.5)' : 'translate(-50%, -50%) scale(1)'
                        }}
                        title={`Pin ${pin.id}`}

                    >
                        <span className='country text-start' style={{ position: 'absolute', marginLeft: '', color: 'white', width: 'auto', display: ind === pin.id ? 'block' : 'none' }}>
                            <strong style={{ whiteSpace: "nowrap" }}>
                                {pin.title}
                            </strong>
                        </span>
                        <PinIcon />
                    </div>
                ))}

            </div>
            <div ref={contactRef} className="container" style={{ maxWidth: '90vw' }}>

                <div className="row mb-5" style={{ position: 'relative', zIndex: '2' }}>

                    {contact.map(renderContact)}

                </div>
            </div>
        </div >
    );

}

export default Contact