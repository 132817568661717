import React, { useEffect, useState } from 'react'
import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import Dealership2 from '../Home/Dealership';
import Background from '../Home/Media/Layer_2.png'

function Dealership() {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true);
    const [dealership, setDealership] = useState({});

    useEffect(() => {
        const init = async () => {
            try {

                const res = await RequestManager.fetchDealershipPage();
                setDealership(TransformHome(res?.data) ?? {})
                setLoading(false);
            } catch (err) {
                console.error("Error in fetching data: ", err);
                setError(err);
            }
        }
        init();
    }, [])
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <div className="row">
                        <div className="col-12 text-center">
                            {loading && <p>Loading...</p>}
                            {error && <p className="text-danger">Error: {error}</p>}

                        </div>
                    </div>
                </div>
            </div>
            {!loading && !error && (
                <div className="container-fluid px-0">
                    <div className="row bg-dark">
                        <div className="col-12 Main d-flex align-items-center"
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_API_URL}${dealership.Image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',

                                width: '100%',
                                fontSize: 'var(--Title)',
                                color: 'white',
                            }}>
                            <div className="container" style={{ maxWidth: '90vw' }}>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>
                                            DEALERSHIPS
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{ maxWidth: '90vw' }}>
                        <div className="row mt-5 text-center" style={{ color: 'var(--grey)' }}>
                            <div className="col-12" style={{ fontSize: 'var(--Regular)' }}>
                                Our dealerships proudly offer a range of cutting-edge solutions tailored for the electromechanical, fabrication, construction, and operation and maintenance industries. By leveraging the latest advancements in technology, our products enhance efficiency, streamline processes, and improve safety standards across various applications. From state-of-the-art machinery that optimizes fabrication workflows to innovative electromechanical systems that ensure seamless operations, our offerings are designed to meet the unique challenges of each sector. With a commitment to quality and customer satisfaction, we empower businesses to achieve their goals and stay ahead in a competitive landscape.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-5"
                                style={{
                                    backgroundImage: `url(${Background})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'right top'
                                }}
                            >
                                <Dealership2 />
                            </div>
                        </div>
                    </div>

                </div>

            )
            }

        </div>
    )
}

export default Dealership