import React from 'react'
import './Button.css'
function Button() {
    return (

        <button className='d-flex align-items-center Read_More' >
            <div className="col-auto">
                Read More
            </div>
            <div className="col-auto">
                <svg className='arrow_right' width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4038 24.5641C19.5151 24.5641 24.4688 19.6104 24.4688 13.5C24.4688 7.38956 19.5151 2.4359 13.4038 2.4359C7.29338 2.4359 2.34056 7.38955 2.34056 13.5C2.34056 19.6104 7.29337 24.5641 13.4038 24.5641ZM13.4038 3.32099C19.0173 3.32099 23.5837 7.88737 23.5837 13.5C23.5837 19.1126 19.0173 23.6781 13.4038 23.6781C7.79119 23.6781 3.22566 19.1118 3.22566 13.5C3.22566 7.88821 7.79119 3.32099 13.4038 3.32099Z" fill="#1B7A99" />
                    <path d="M14.0756 18.2562L14.7017 18.8823L20.084 13.5L14.7017 8.11773L14.0756 8.74379L18.3897 13.0579L7.65217 13.0579L7.65217 13.943L18.3897 13.943L14.0756 18.2562Z" fill="#1B7A99" />
                </svg>
            </div>

        </button>

    )
}

export default Button