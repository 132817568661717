import React from 'react'
import CountUp from 'react-countup';
import CountUpComponent from '../Shared/CountUpComponent';

function GeneralInfo({ home }) {
    return (
        <div className='row d-flex align-items-center justify-content-center' style={{ color: 'var(--primary)', textTransform:'uppercase' }}>
            <div className="col-lg-4 col-12 mt-5 d-flex align-content-center flex-wrap flex-column" >
                <div className="row d-flex align-items-center">
                    <div className="col-auto">
                        <svg width="4" height="48" viewBox="0 0 4 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="2" x2="2" y2="48" stroke="#397995" strokeWidth="4" />
                        </svg>
                    </div>
                    <div className="col-auto" style={{ fontSize: 'var(--Regular)' }}>
                        PROJECTS
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto d-flex align-items-center" style={{ fontSize: 'var(--Title)', fontWeight: 'bold', borderBottom: '2px solid var(--primary)' }}>
                    <CountUpComponent end={home.Projects} />+
                        
                    </div>
                </div>
                <div className="row mt-4">
                    <div dangerouslySetInnerHTML={{ __html: home.Projects_Description }} className="col-auto d-flex align-items-center" style={{ color: 'var(--grey)', fontSize: '12px' }}>

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-12 mt-5 d-flex align-content-center flex-wrap flex-column" >
                <div className="row d-flex align-items-center">
                    <div className="col-auto">
                        <svg width="4" height="48" viewBox="0 0 4 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="2" x2="2" y2="48" stroke="#397995" strokeWidth="4" />
                        </svg>
                    </div>
                    <div className="col-auto" style={{ fontSize: 'var(--Regular)' }}>
                        YEARS
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto d-flex align-items-center" style={{ fontSize: 'var(--Title)', fontWeight: 'bold', borderBottom: '2px solid var(--primary)' }}>

                    <CountUpComponent end={home.Years} />+
                    </div>
                </div>
                <div className="row mt-4">
                    <div dangerouslySetInnerHTML={{ __html: home.Years_Description }} className="col-auto d-flex align-items-center justify-content-center" style={{ color: 'var(--grey)', fontSize: '12px' }}>

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-12 mt-5 d-flex align-content-center flex-wrap flex-column" >
                <div className="row d-flex align-items-center">
                    <div className="col-auto">
                        <svg width="4" height="48" viewBox="0 0 4 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="2" x2="2" y2="48" stroke="#397995" strokeWidth="4" />
                        </svg>
                    </div>
                    <div className="col-auto" style={{ fontSize: 'var(--Regular)' }}>
                        DEALERSHIPS
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto d-flex align-items-center" style={{ fontSize: 'var(--Title)', fontWeight: 'bold', borderBottom: '2px solid var(--primary)' }}>

                    <CountUpComponent end={home.Dealerships} />+
                    </div>
                </div>
                <div className="row mt-4">
                    <div dangerouslySetInnerHTML={{ __html: home.Dealerships_Description }} className="col-auto d-flex align-items-center justify-content-center" style={{ color: 'var(--grey)', fontSize: '12px' }}>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralInfo