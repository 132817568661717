import React, { useState, useEffect } from 'react'
import { RequestManager } from '../Requests/RequestManager';
import TransformBusiness from '../Transformer/TransformBusiness';
import Button2 from '../Shared/Button2';
import { useNavigate } from 'react-router-dom';

function Blog() {
    const [blog, setBlog] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchBlog();
            
                setBlog(TransformBusiness(res?.data) ?? []);
            } catch (error) {
                console.error("Error in fetching data: ", error);
            }
        }

        init();
    }, [])

    const renderBlog = (item, index) => (
        <div key={index} className="col-12 col-lg-4 mt-5 d-flex align-items-center justify-content-center">
            <div className="row p-3 d-flex align-items-center justify-content-center">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="100%" alt="" style={{ borderRadius: '10px' }} />
                </div>
                <div className="col-12 d-lg-block d-flex align-items-center justify-content-center mt-4" style={{ fontSize: 'var(--Regular)' }}>
                    <strong>
                        {item.Title}
                    </strong>
                </div>
                <div dangerouslySetInnerHTML={{ __html: item.Description }} className="col-12 d-lg-block d-flex align-items-center justify-content-center mt-4">

                </div>
                <div className="col-12 d-lg-block  d-flex align-items-center justify-content-center mt-4">
                    <div className="row">
                        <div onClick={() => navigate(`/news/${item.id}`)} className="col-auto">

                            <Button2 />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
    return (
        <div className='row mb-5 py-5'>
            <div className="col-12">
                <div className="row ">
                    {blog.map(renderBlog)}
                </div>
            </div>
        </div>
    )
}

export default Blog