import React from 'react'
import Logo from '../Navbar/Media/Logo'
import { useNavigate } from 'react-router-dom'
import Linkedin from './Media/linkedin-updated.png'
import Facebook from './Media/facebook.png'

const Quick_Links = [
    {
        Title: 'HOME',
        Link: '/',
    },
    {
        Title: 'ABOUT US',
        Link: '/about-us'
    },
    {
        Title: 'OUR BUSINESS',
        Link: '/our-business'
    },
    {
        Title: 'WHERE WE WORK',
        Link: '/where-we-work'
    },

]

const Company = [
    {
        Title: 'PRIVACY POLICY',
        Link: '/privacy-policy',
    },
    {
        Title: 'DISCLAIMERS',
        Link: '/disclaimers'
    },
    {
        Title: 'TERMS & CONDITIONS',
        Link: '/terms-and-conditions'
    },
    {
        Title: 'CAREERS',
        Link: '/career'
    },
    {
        Title: 'CONTACT US',
        Link: '/contact-us'
    }
]




function Footer() {

    const navigate = useNavigate();
    const renderLink = (item, index) => (
        <div onClick={() => navigate(`${item.Link}`)} key={index} className="col-auto Hover">

            {item.Title}
        </div>
    )
    return (
        <div className='container-fluid py-5' style={{ backgroundColor: '#F7F7F7' }}>
            <div className="row">
                <div className="col-12">

                    <div className="container" style={{ maxWidth: '1440px' }}>
                        <div className="row" style={{ fontSize: 'var(--Regular)', color: 'var(--grey)' }}>
                            <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                                <Logo />
                            </div>
                            <div className="col-12 mt-5 mt-lg-0 col-lg-4 d-flex align-items-start justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row text-center text-lg-start d-flex flex-column align-content-start">
                                            <strong className='mb-4' style={{ color: 'var(--primary)' }}>
                                                Quick Links
                                            </strong>
                                            {Quick_Links.map(renderLink)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-5 mt-lg-0 col-lg-4 d-flex align-items-start justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row text-center text-lg-start d-flex flex-column align-content-start">
                                            <strong className='mb-4' style={{ color: 'var(--primary)' }}>
                                                Company
                                            </strong>
                                            {Company.map(renderLink)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-lg-start justify-content-center mt-5">
                            <div className="col-lg-10 col-12">
                                <div className="row d-flex align-items-center justify-content-center">


                                    <div className="col-lg col-12 ml-auto d-flex align-items-center justify-content-center" style={{ fontSize: '14px', color: 'var(--primary)' }}>
                                        © All rights reserved for EncoGroup 2024
                                    </div>
                                    <div className="col-auto text-center">
                                        <a rel="noopener noreferrer" target='_blank' href="https://www.instagram.com/encogroupnet/">

                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="19.999" r="20" fill="#1B7A99" />
                                                <path d="M20.0016 16.4091C18.0152 16.4091 16.4129 18.0117 16.4129 19.9985C16.4129 21.9854 18.0152 23.588 20.0016 23.588C21.988 23.588 23.5902 21.9854 23.5902 19.9985C23.5902 18.0117 21.988 16.4091 20.0016 16.4091ZM20.0016 22.3321C18.7179 22.3321 17.6685 21.2856 17.6685 19.9985C17.6685 18.7115 18.7148 17.6649 20.0016 17.6649C21.2883 17.6649 22.3346 18.7115 22.3346 19.9985C22.3346 21.2856 21.2852 22.3321 20.0016 22.3321ZM24.574 16.2623C24.574 16.7278 24.1992 17.0995 23.737 17.0995C23.2716 17.0995 22.8999 16.7246 22.8999 16.2623C22.8999 15.7999 23.2747 15.4251 23.737 15.4251C24.1992 15.4251 24.574 15.7999 24.574 16.2623ZM26.9508 17.112C26.8977 15.9905 26.6416 14.9971 25.8202 14.1786C25.0019 13.3601 24.0087 13.104 22.8875 13.0477C21.7318 12.9821 18.2682 12.9821 17.1125 13.0477C15.9944 13.1008 15.0012 13.357 14.1798 14.1755C13.3584 14.994 13.1054 15.9874 13.0492 17.1089C12.9836 18.2647 12.9836 21.7292 13.0492 22.8851C13.1023 24.0066 13.3584 25 14.1798 25.8185C15.0012 26.6369 15.9913 26.8931 17.1125 26.9493C18.2682 27.0149 21.7318 27.0149 22.8875 26.9493C24.0087 26.8962 25.0019 26.6401 25.8202 25.8185C26.6385 25 26.8946 24.0066 26.9508 22.8851C27.0164 21.7292 27.0164 18.2679 26.9508 17.112ZM25.4579 24.1253C25.2143 24.7376 24.7427 25.2093 24.1274 25.4561C23.206 25.8216 21.0197 25.7372 20.0016 25.7372C18.9834 25.7372 16.794 25.8185 15.8757 25.4561C15.2636 25.2124 14.792 24.7407 14.5452 24.1253C14.1798 23.2037 14.2641 21.0169 14.2641 19.9985C14.2641 18.9801 14.1829 16.7902 14.5452 15.8718C14.7888 15.2595 15.2605 14.7878 15.8757 14.541C16.7971 14.1755 18.9834 14.2598 20.0016 14.2598C21.0197 14.2598 23.2091 14.1786 24.1274 14.541C24.7395 14.7847 25.2112 15.2564 25.4579 15.8718C25.8233 16.7934 25.739 18.9801 25.739 19.9985C25.739 21.0169 25.8233 23.2068 25.4579 24.1253Z" fill="white" />
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="col-auto text-center">
                                        <a rel="noopener noreferrer" target='_blank' href="https://www.linkedin.com/company/encogroup/">

                                            <img src={Linkedin} width={40} alt="" />
                                        </a>

                                    </div>
                                    <div className="col-auto text-center d-flex align-items-center justify-content-center">
                                        <a rel="noopener noreferrer" target='_blank' href="https://www.facebook.com/Encogroup.net/">

                                            <img src={Facebook} width={40} alt="" />
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer