import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import About from './Components/About Us/About';
import OurBusiness from './Components/Our Business/OurBusiness';
import SingleBusiness from './Components/Our Business/SingleBusiness';
import Dealership from './Components/Dealership/Dealership';
import SingleDealer from './Components/Dealership/SingleDealer';
import Where from './Components/Where We Work/Where';
import Sustainability from './Components/Sustainability/Sustainability';
import Career from './Components/Career/Career';
import News from './Components/News/News';
import Contact from './Components/Contact Us/Contact';
import SingleNews from './Components/News/SingleNews';
import ApplicationDetail from './Components/ApplicationDetail/ApplicationDetail'; // Import your new component

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/our-business" element={<OurBusiness />} />
        <Route path="/our-business/:SingleBusinessId" element={<SingleBusiness />} />
        <Route path="/dealership" element={<Dealership />} />
        <Route path="/dealership/:SingleDealerId" element={<SingleDealer />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/where-we-work" element={<Where />} />
        <Route path="/career" element={<Career />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/news/:SingleBusinessId" element={<SingleNews />} />
        <Route path="/applications/:id" element={<ApplicationDetail />} /> {/* Add this new route */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
