import React from 'react'
import Blog from '../Home/Blog'

function News() {
    return (
        <div className='container' style={{ maxWidth: '90vw' }}>
            <div className="row">
                <div className="col-12" style={{ fontSize: 'var(--Title)', color: 'var(--primary)' }}>
                    <strong>
                        NEWS
                    </strong>
                </div>
                <div className="col-12">
                    <Blog />
                </div>
            </div>
        </div>
    )
}

export default News