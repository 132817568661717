import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Background from '../Home/Media/Layer_1.png'
function Sectors({ sector }) {
    const renderSector = (item, index) => (
        <SwiperSlide key={index}>
            <div className="row d-flex align-items-center" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '80% center',

            }}>
                <div className="col-6 pb-5">
                    <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="100%" height="100%" alt="" style={{borderRadius:'25px'}}/>
                </div>
                <div className="col-6 w-25 d-flex h-25 align-items-center" style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                    borderRadius: '7px',
                    fontSize: 'var(--subTitle)',
                    color: 'var(--primary)',
                }}>
                    <strong>
                        {item.Title}
                    </strong>
                </div>
            </div>
        </SwiperSlide>
    )

    return (
        <Swiper
            modules={[Navigation]}
            navigation
        >
            {sector.map(renderSector)}
        </Swiper>


    )
}

export default Sectors