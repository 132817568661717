function TransformHome(data) {
    return {
      id: data.id,
      ...data.attributes,
      HeroMedia: data.attributes.HeroMedia?.data?.attributes?.url,
      Image: data.attributes.Image?.data?.attributes?.url,
    };
  }
  
  export default TransformHome;
  