import React, { useEffect, useState } from 'react';
import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import TransformBusiness from '../Transformer/TransformBusiness';


import Button2 from '../Shared/Button2'
import { useNavigate } from 'react-router-dom';
import Sectors from './Sectors';

function OurBusiness() {
    const [business, setBusiness] = useState({});
    const [sector, setSector] = useState([]);
    const [ind, setInd] = useState(null);
    const [service, setService] = useState([]);
    const [bus, setBus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const Nav = (id) => {
        navigate(`/our-business/${id}`);
    }

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchOurBusiness();
                setBusiness(TransformHome(res?.data) ?? {});
            } catch (err) {
                console.error("Error in fetching data: ", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        const init2 = async () => {
            try {
                const res = await RequestManager.fetchBusiness();
                setBus(TransformBusiness(res?.data) ?? []);
            } catch (err) {
                console.error("Error in fetching data: ", err);
            }
        }

        const init4 = async () => {
            try {
                const res = await RequestManager.fetchServices();
                setService(TransformBusiness(res?.data) ?? []);
            } catch (err) {
                console.error("Error in fetching data: ", err);
            }
        }

        const init3 = async () => {
            try {

                const res = await RequestManager.fetchSectors();
                setSector(TransformBusiness(res?.data) ?? []);

            } catch (err) {
                console.error("Error in fetching Sectors, ", err);
            }
        }
        init();
        init2();
        init3();
        init4();

    }, []);

    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    }

    const renderBusiness = (item, index) => (

        <div key={index} className="col-12 col-md-6 col-lg-4 px-5 py-5">
            <div className="row">


                <div className="col-12 ">
                    <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="100%" height="300px" alt="" style={{ borderRadius: '10px' }} />
                </div>
                <div className="col-12 mt-3" style={{ fontSize: 'var(--Regular)', fontWeight: 'bold' }}>
                    {item.Title}
                </div>
                <div dangerouslySetInnerHTML={{ __html: truncateText(item.Description, 200) }} className="col-12 mt-3">

                </div>
                <div onClick={() => Nav(item.id)} className="col-auto mt-3">
                    <Button2 />
                </div>
            </div>
        </div>

    )

    const renderBus = (item, index) => (
        <div key={index} onClick={() => setInd(index)} className="col-12 Hover py-4 mt-2" style={{
            backgroundColor: ind === index ? 'var(--primary)' : 'white',
            borderRadius: '10px',
            color: ind === index ? 'white' : 'var(--grey)',
            boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
            fontSize: 'var(--Regular)',
            cursor: 'pointer'
        }}>
            <div className="row">
                <div className="col-12">
                    <strong>
                        {item.Title}
                    </strong>
                </div>
                {ind === index && (


                    <div className="col-12 mt-2 " style={{ fontWeight: 'lighter' }}>
                        <ul>
                            {item.Bullets.map((item2, index2) => (
                                <li key={index2}>
                                    {item2.Title}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <div className="row">
                        <div className="col-12 text-center">
                            {loading && <p>Loading...</p>}
                            {error && <p className="text-danger">Error: {error}</p>}

                        </div>
                    </div>
                </div>
            </div>
            {!loading && !error && (
                <div className="container-fluid px-0">
                    <div className="row bg-dark">
                        <div className="col-12 Main d-flex align-items-center"
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_API_URL}${business.Image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',

                                width: '100%',
                                fontSize: 'var(--Title)',
                                color: 'white',
                            }}>
                            <div className="container" style={{ maxWidth: '90vw' }}>

                                <div className="row">
                                    <div className="col-12">

                                        <span style={{ fontWeight: 'lighter' }}>
                                            OUR &nbsp;
                                        </span>
                                        <strong>
                                            BUSINESS
                                        </strong>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="container" style={{ maxWidth: '90vw' }}>
                            <div className="row mt-5">
                                {bus.map(renderBusiness)}
                            </div>

                            <div className="row mt-5">
                                <div className="col-12" style={{ fontSize: 'var(--subTitle)', color: 'var(--primary)' }}>
                                    <strong>
                                        SERVICES
                                    </strong>
                                </div>
                                <div className="col-12 mt-5">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                {service.map(renderBus)}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row Sticky">
                                                <div className="col-12">
                                                    {ind != null && (
                                                        <img className='service_Image' src={`${process.env.REACT_APP_API_URL}${service[ind].Image}`} alt="" width="100%" height="auto" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-12 mt-5" style={{ fontSize: 'var(--subTitle)', color: 'var(--primary)' }}>
                                    <strong>
                                        SECTORS WE SERVE
                                    </strong>
                                </div>
                                <div className="col-12 mt-5">
                                    <Sectors sector={sector} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
}

export default OurBusiness;
