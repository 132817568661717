import React, { useEffect, useState, useRef } from 'react';
import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import './Where.css'
import 'swiper/css';
import 'swiper/css/pagination';
import TransformBusiness from '../Transformer/TransformBusiness';
import Project from './Project';
import Pin from './Pin.svg'

function Where() {
    const [where, setWhere] = useState({});
    const [project, setProject] = useState([]);
    const [selected, setSelected] = useState('GULF');
    const pro = useRef(null);


    const [selectedCountry, setSelectedCountry] = useState("BAHRAIN");
    const [continent, setContinent] = useState("GULF");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [location, setLocation] = useState("BAHRAIN");
    const [ind, setInd] = useState(3);
    const pins = [
        { id: 1, title: 'LEBANON', top: '35%', left: '45.5%', search: 'LEBANON', continent: "MIDDLE EAST" },
        { id: 2, title: 'KSA', top: '42%', left: '50%', search: 'KSA', continent: "GULF" },
        { id: 3, title: 'BAHRAIN', top: '45.5%', left: '55%', search: 'BAHRAIN', continent: "GULF" },
        { id: 4, title: 'BURKINA FASO', top: '56%', left: '27%', search: 'BURKINA FASO', continent: "AFRICA" },
        { id: 5, title: 'OMAN', top: '43.5%', left: '53.5%', search: 'OMAN', continent: "GULF" },
        { id: 6, title: "COTE D'IVOIRE", top: '61%', left: '25%', search: "COTE D'IVOIRE", continent: "AFRICA" },
        { id: 7, title: 'LITHUANIA', top: '6.5%', left: '40%', search: 'LITHUANIA', continent: "EUROPE" },
        { id: 8, title: 'DJIBOUTI', top: '57%', left: '49%', search: 'DJIBOUTI', continent: "AFRICA" },
        { id: 9, title: 'SUDAN', top: '53%', left: '44%', search: 'SUDAN', continent: "AFRICA" },
        { id: 10, title: 'BOSNIA', top: '22.5%', left: '36.3%', search: 'BOSNIA', continent: "EUROPE" },
        { id: 11, title: 'UAE', top: '49%', left: '56%', search: 'UAE', continent: "UAE" },
        { id: 12, title: "SOUTH AFRICA", top: '97%', left: '41%', search: "SOUTH AFRICA", continent: "AFRICA" },
        { id: 2, title: 'IRAQ', top: '37%', left: '50%', search: 'IRAQ', continent: "GULF" },
    ];

    const handleHashChange = () => {
        const hash = window.location.hash.replace('#', '');
        if (hash) {

            setSelected(hash.toUpperCase());
        }
    };

    const fetchContinent = async (param) => {
        const res = await RequestManager.fetchContinent(param);
        setProject(TransformBusiness(res?.data) ?? []);

    }

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchWhereWeWork();
                const res2 = await RequestManager.fetchProject(selectedCountry);
                setWhere(TransformHome(res?.data) ?? {});
                setProject(TransformBusiness(res2?.data) ?? []);

            } catch (err) {
                console.error("Error in fetching data: ", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        init();
        handleHashChange();

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };


    }, [selectedCountry]);


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <div className="row">
                        <div className="col-12 text-center">
                            {loading && <p>Loading...</p>}
                            {error && <p className="text-danger">Error: {error}</p>}

                        </div>
                    </div>
                </div>
            </div>
            {!loading && !error && (
                <div className="px-0">
                    <div className="map-container">
                        <img src={`${process.env.REACT_APP_API_URL}${where.HeroMedia}`} style={{ position: 'relative', zIndex: '-10' }} className='map-image' alt="Map" />
                        {pins.map(pin => (
                            <div
                                key={pin.id}
                                href={pin.link}
                                id='Pin'
                                onClick={() => {
                                    setInd(pin.id);
                                    setLocation(pin.search);
                                    setSelectedCountry(pin.title);
                                    setContinent(pin.continent);
                                    setSelected(pin.continent)
                                    pro.current.scrollIntoView({ behavior: 'smooth' });


                                }}
                                className="map-pin "
                                style={{
                                    top: pin.top,
                                    left: pin.left,
                                    zIndex: '0',
                                    transform: ind === pin.id ? 'translate(-50%, -50%) scale(1.1)' : 'translate(-50%, -50%) scale(1)',
                                }}
                                title={`Pin ${pin.id}`}


                            >
                                <span className='country text-start' style={{ position: 'relative', marginLeft: '', color: 'black', display: 'block', zIndex: '0', fontWeight: 'bold' }}>
                                    <strong style={{ whiteSpace: "nowrap" }}>
                                        {pin.title}
                                    </strong>
                                    <img src={Pin} width={20} alt="" />
                                </span>

                            </div>
                        ))}

                    </div>
                    <div className="row">
                        <div className="container" style={{ maxWidth: '90vw' }}>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="row">

                                        <div className="col-12 col-lg-3 px-5">
                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("GULF");
                                                    fetchContinent("GULF");
                                                }}
                                                    className="col-12 Location Hover" style={{
                                                        backgroundColor: selected === `GULF` ? 'var(--primary)' : 'white',
                                                        color: selected === `GULF` ? 'White' : 'inherit'
                                                    }}>
                                                    <strong>

                                                        GCC
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 px-5">
                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("EUROPE");
                                                    setContinent("EUROPE");
                                                    fetchContinent("EUROPE");
                                                }} className="col-12 Location Hover" style={{
                                                    backgroundColor: selected === `EUROPE` ? 'var(--primary)' : 'white',
                                                    color: selected === `EUROPE` ? 'White' : 'inherit'
                                                }} >
                                                    <strong>
                                                        EUROPE
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 px-5">

                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("AFRICA");
                                                    setContinent("AFRICA");
                                                    fetchContinent("AFRICA");

                                                }}
                                                    className="col-12 Location Hover"
                                                    style={{
                                                        backgroundColor: selected === `AFRICA` ? 'var(--primary)' : 'white',
                                                        color: selected === `AFRICA` ? 'White' : 'inherit'
                                                    }}>
                                                    <strong>
                                                        AFRICA
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 px-5">
                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("ME");
                                                    setContinent("MIDDLE EAST");
                                                    fetchContinent("MIDDLE EAST");

                                                }} className="col-12 Location Hover" style={{
                                                    backgroundColor: selected === `ME` ? 'var(--primary)' : 'white',
                                                    color: selected === `ME` ? 'White' : 'inherit'
                                                }}>
                                                    <strong>
                                                        MIDDLE EAST
                                                    </strong>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {selected === "ME" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div onClick={() => setSelectedCountry("LEBANON")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'LEBANON' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'LEBANON' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            LEBANON
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("IRAQ")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'IRAQ' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'IRAQ' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            IRAQ
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selected === "GULF" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">

                                                    <div onClick={() => setSelectedCountry("BAHRAIN")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'BAHRAIN' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'BAHRAIN' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            BAHRAIN
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("KSA")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'KSA' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'KSA' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            KSA
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("OMAN")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'OMAN' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'OMAN' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            OMAN
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("UAE")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'UAE' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'UAE' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            UAE
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selected === "EUROPE" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div onClick={() => setSelectedCountry("LITHUANIA")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'LITHUANIA' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'LITHUANIA' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            LITHUANIA

                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("BOSNIA")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'BOSNIA' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'BOSNIA' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            BOSNIA AND HERZEGOVINA

                                                        </strong>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                        {selected === "AFRICA" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">

                                                    <div onClick={() => setSelectedCountry("BURKINA FASO")} className="col-12 col-lg-2 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === "BURKINA FASO" ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === "BURKINA FASO" ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            BURKINA FASO
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("COTE D'IVOIRE")} className="col-12 col-lg-2 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === "COTE D'IVOIRE" ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === "COTE D'IVOIRE" ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            COTE D'IVOIRE
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("DJIBOUTI")} className="col-12 col-lg-2 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === "DJIBOUTI" ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === "DJIBOUTI" ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            DJIBOUTI
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("MALI")} className="col-12  col-lg-2 text-center Hover py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === "MALI" ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === "MALI" ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            MALI
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("SUDAN")} className="col-12  col-lg-2 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'SUDAN' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'SUDAN' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            SUDAN
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("SOUTH AFRICA")} className="col-12   col-lg-2 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'SOUTH AFRICA' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'SOUTH AFRICA' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            SOUTH AFRICA
                                                        </strong>
                                                    </div>


                                                </div>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12" ref={pro}>
                                    {
                                        project.length > 0 ? (
                                            <>
                                                {project && <Project project={project} />}
                                            </>
                                        ) : (
                                            <p>No projects available</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default Where;
