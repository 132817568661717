import React, { useEffect, useState } from 'react'
import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import TransformBusiness from '../Transformer/TransformBusiness';

function Sustainability() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sustain, setSustain] = useState({})
    const [sustainablity, setSustainability] = useState([]);
    const [ind, setInd] = useState(null);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchSustain();
                const res2 = await RequestManager.fetchSustainability();
                setSustain(TransformHome(res?.data) ?? {})
                setSustainability(TransformBusiness(res2?.data) ?? [])
                setLoading(false);
            } catch (err) {
                console.error("Error in fetching data: ", err)
                setError(err);
            }
        }
        init();
    }, [])

    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    }

    const renderSus = (item, index) => (
        <div key={index} className="row my-5 d-flex justify-content-center align-items-center" style={{ fontSize: 'var(--Regular)' }}>
            <div onClick={() => setInd(index)} className="col-12 col-lg-7 py-5 d-flex Hover justify-content-center align-items-center" style={{
                borderRadius: '10px',
                boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                backgroundColor: index === ind ? 'var(--primary)' : 'white'

            }}>
                <div className="row d-flex justify-content-center align-items-center">

                    <div className="col-12 d-flex justify-content-start align-items-center" style={{ color: index === ind ? 'white' : 'var(--primary)' }}>
                        <strong>
                            {item.Title}
                        </strong>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: index === ind ? item.Description : truncateText(item.Description, 100) }} className="col-12 mt-3 d-flex justify-content-center align-items-center" style={{ color: index === ind ? 'white' : 'var(--grey)' }}>

                    </div>
                    {index !== ind && (
                        <div className="col-10 mt-3 d-flex align-items-center justify-content-end" style={{ color: 'var(--primary)' }}>
                            <strong>

                                Read more <svg width="45" height="16" viewBox="0 0 45 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M35.3726 15.0694L36.3758 16L45 8L36.3758 2.16794e-06L35.3726 0.930556L42.2852 7.34284L8.23306e-07 7.34284L6.98194e-07 8.65841L42.2852 8.65841L35.3726 15.0694Z" fill="#1B7A99" />
                                </svg>

                            </strong>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <div className="row">
                        <div className="col-12 text-center">
                            {loading && <p>Loading...</p>}
                            {error && <p className="text-danger">Error: {error}</p>}
                        </div>
                    </div>
                </div>
            </div>
            {!loading && !error && (
                <div className="container-fluid px-0">
                    <div className="row bg-dark">
                        <div className="col-12 Main d-flex align-items-center"
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_API_URL}${sustain.HeroMedia})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                
                                width: '100%',
                                fontSize: 'var(--Title)',
                                color: 'white',
                            }}>
                            <div className="container" style={{ maxWidth: '90vw' }}>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>
                                            SUSTAINABILITY
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-5" style={{ maxWidth: '90vw' }}>
                        <div className="row">
                            <div className="col-12 d-flex align-items-center justify-content-center" >
                                {/* <img src={`${process.env.REACT_APP_API_URL}${sustain.Image}`} height="100%" width="100%" alt="" /> */}
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                {sustainablity.map(renderSus)}
                            </div>
                        </div>
                    </div>
                </div>

            )
            }

        </div>
    )
}

export default Sustainability