import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosInstance } from '../Axios/Axios';

const ApplicationDetail = () => {
    const { id } = useParams(); // Get the application ID from the URL
    const [application, setApplication] = useState(null); // Assuming response is an object, not an array
    const [loading, setLoading] = useState(true); // To handle loading state
    const [error, setError] = useState(null); // To handle error state

    // Async function to fetch application data
    const fetchApplication = async () => {
        try {
            // Corrected the URL to use the 'id' in the path, not as a query param
            const response = await AxiosInstance.get(`/api/applies/${id}?populate=*`);
            console.log(response.data);
            setApplication(response?.data); // Assuming response.data contains the application object
        } catch (error) {
            setError('Error fetching application details.');
            console.error('Error fetching application details:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch the application data when the component mounts or when 'id' changes
    useEffect(() => {
        fetchApplication();
    }, [id]); // Dependency on 'id' so it re-fetches when 'id' changes

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    // Ensure application is an object before trying to render it
    if (!application) {
        return <div>No application data available.</div>;
    }

    return (
        <div className='container' style={{ maxWidth: '1440px' }}>
            <h1>Application Details</h1>
            <p>Name: {application.data.attributes.Name}</p>
            <p>Email: {application.data.attributes.Email}</p>
            <p>Phone: {application.data.attributes.Phone}</p>
            <p>Country: {application.data.attributes.Country}</p>
            <p>Description: {application.data.attributes.Description}</p>
            <p>
                CV: {application.data.attributes?.CV ? (
                    <a href={`https://admin.encogroup.net/${application.data.attributes.CV.data.attributes.url}`} target="_blank" rel="noopener noreferrer">
                        Download CV
                    </a>
                ) : 'No CV attached'}
            </p>
        </div>
    );
};

export default ApplicationDetail;
