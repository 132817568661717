import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import Background from '../Home/Media/Layer_2.png'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';

function SingleDealer() {
    const { SingleDealerId } = useParams();

    const [dealer, setDealer] = useState({});
    const [loading, setLoading] = useState(true);
    const [ind, setInd] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchSingleDealer(SingleDealerId);
                setDealer(TransformHome(res?.data) ?? {});
                console.log(res?.data);
                setLoading(false);
            } catch (error) {
                console.error("Error in fetching data: ", error);
            }
        };
        init();
    }, [SingleDealerId]);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    return (
        <div className="container">
            {loading ? (
                <>Loading...</>
            ) : (
                <div className='container' style={{ maxWidth: '90vw' }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row" style={{ height: '60vh' }}>
                                <div className="col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-end justify-content-center" style={{
                                    fontSize: 'var(--Title2)',
                                    color: 'var(--primary)',
                                    backgroundImage: `url(${Background})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center right',
                                    backgroundSize: '500px',
                                }}>
                                    <strong>
                                        {dealer.Title}
                                    </strong>
                                    <span style={{ fontSize: 'var(--Regular)' }}>
                                        <a
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                            href={`${dealer.Link.startsWith('http') ? dealer.Link : `https://${dealer.Link}`}`}
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                            {dealer.Link}
                                        </a>
                                    </span>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: dealer.Description }} className="col-12 col-lg-6 d-flex align-items-center" style={{
                                    fontSize: 'var(--Regular)',
                                    color: 'var(--grey)'
                                }}></div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5 pt-5">
                        <Tabs defaultActiveKey="photos" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="photos" title="Photos">
                                <div className="col-12 text-center">
                                    <div className="row d-flex align-items-center justify-content-center">
                                        {dealer?.Gallery?.data?.map((item, index) => (
                                            <div key={index} className="col-12 col-lg-auto d-flex align-items-center justify-content-center mt-2">
                                                <img
                                                    src={`${process.env.REACT_APP_API_URL}${item?.attributes?.url}`}
                                                    height="150px"
                                                    width="auto"
                                                    alt=""
                                                    onClick={() => handleImageClick(`${process.env.REACT_APP_API_URL}${item?.attributes?.url}`)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="videos" title="Videos">
                                <div className="row d-flex align-items-center justify-content-start">


                                    {dealer?.Videos?.map((item, index) => {

                                        const videoId = item.Link.split('v=')[1];
                                        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

                                        return (
                                            <div key={index} className="col-12 col-lg-auto">
                                                <iframe
                                                    width="100%"
                                                    height="500px"
                                                    src={embedUrl}
                                                    title={`YouTube video ${index + 1}`}

                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="row d-flex align-items-center justify-content-center">
                                <div className="col-12 mb-5">
                                    {dealer?.Services?.map((item, index) => (
                                        <div key={index} className="row Hover d-flex align-items-center justify-content-center">
                                            <div onClick={() => setInd(index)} className="col-6 mt-5 py-5 text-center" style={{
                                                borderRadius: '10px',
                                                boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                backgroundColor: index === ind ? 'var(--primary)' : 'white',
                                                color: index === ind ? 'white' : 'var(--primary)'
                                            }}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src={`${process.env.REACT_APP_API_URL}${item?.Image?.data?.attributes?.url}`} width="auto" height="80px" alt="" style={{
                                                            filter: index === ind ? 'brightness(0) invert(1)' : 'none'
                                                        }} />
                                                    </div>
                                                    <div className="col-12 mt-5" style={{ fontSize: 'var(--Regular)' }}>
                                                        <strong>
                                                            {item.Title}
                                                        </strong>
                                                    </div>
                                                    {index === ind && (
                                                        <div dangerouslySetInnerHTML={{ __html: item.Description }} className="col-12 mt-5"></div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal for full-scale image */}
                    <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                        <Modal.Body className="p-0">
                            <img src={selectedImage} alt="Selected" style={{ width: '100%', height: 'auto' }} />
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    )
}

export default SingleDealer;
