import React, { useEffect, useState } from 'react'
import { RequestManager } from '../Requests/RequestManager'
import TransformBusiness from '../Transformer/TransformBusiness'
import { useNavigate } from 'react-router-dom';


function Business() {
  const [business, setBusiness] = useState([]);
  const navigate = useNavigate();
  const Nav = (id) => {
    navigate(`/our-business/${id}`);
  }

  useEffect(() => {
    const init = async () => {
      try {
        const res = await RequestManager.fetchBusiness();
        setBusiness(TransformBusiness(res?.data ?? []));

      } catch (error) {
        console.error("Error in fetching businesses", error)
      }
    }

    init();
  }, [])


  const renderBusiness = (item, index) => {

    return (
      <div onClick={() => Nav(item.id)} key={index} className="col-lg-4 col-md-6 mt-5 col-12 px-5  d-flex align-items-start justify-content-center" >
        <div className="row Hover h-100" style={{ boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.05)', borderRadius: '10px' }}>
          <div className="col-12 d-flex align-items-start justify-content-center">
            <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="100%" height="200px" alt={item.Title} style={{ borderRadius: '15px' }} />
          </div>
          <div className="col-12 my-3 text-center" style={{ color: 'var(--navy)', fontSize: 'var(--Regular)', textTransform: 'uppercase' }}>
            <strong>
              {item.Title}
            </strong>
          </div>
        </div>
      </div>
    )


  }

  return (
    <div className='row'>
      <div className="col-12">
        <div className="row mt-5">
          {business.map(renderBusiness)}
        </div>
        {/* <div className="row mt-5 d-flex align-items-center justify-content-center">
          <div className="col-auto Hover" style={{ fontSize: 'var(--regular)', color: 'var(--primary)' }}>
            <a href="/our-business" style={{ textDecoration: 'none', color: 'inherit' }}>
              SEE ALL BUSINESS LINES
            </a>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Business